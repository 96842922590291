<template>
  <section>
    <b-alert
      variant="primary"
      show
    >
      <h4 class="alert-heading">
        <feather-icon
          icon="AlertCircleIcon"
          size="12"
        /> &nbsp; Dica
      </h4>
      <div class="alert-body">
        - Dois espaços = quebra de linha
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="12">

          <b-row class="my-1">
            <b-col cols="4">
              <b-form-group
                label="Tipo de Divisão:"
              >
                <b-form-input
                  v-model="param1"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Transformar divisão para:"
              >
                <b-form-input
                  v-model="param2"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Espaçamento de linhas:"
              >
                <b-form-select
                  v-model="extraparam"
                  :state="extraparam === null ? false : true"
                  :options="options"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                variant="relief-primary"
                block
                @click.stop="converter()"
              >
                Converter
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                v-clipboard:copy="text2"
                v-clipboard:success="onCopy"
                size="sm"
                variant="relief-primary"
                class="mb-1"
              >
                Copiar
              </b-button>
            </b-col>

            <b-col cols="6">
              <b-form-textarea
                v-model="text1"
                placeholder=""
                rows="10"
                no-auto-shrink
              />
            </b-col>

            <b-col cols="6">
              <b-form-textarea
                v-model="text2"
                placeholder=""
                rows="10"
                no-auto-shrink
              />
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BFormInput,
  BFormSelect, BAlert
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    BAlert
  },
  data: () => ({
    param1: '',
    param2: '',
    extraparam: '',
    options: [
      {
        value: '',
        text: 'Sem espaço'
      },
      {
        value: '\n',
        text: 'Com Espaço'
      }
    ],
    text1: '',
    text2: ''
  }),
  methods: {
    converter () {
      let param1 = this.param1
      let param2 = this.param2

      if (param1 === '  ') {
        param1 = '\n'
      }

      if (param2 === '  ') {
        param2 = '\n'
      }

      const lines = this.text1.split('\n')
      let content = ''

      for (const line of lines) {
        content += `${line.replaceAll(param1, param2)}${this.extraparam}\n`
      }

      this.text2 = content
    },
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    }
  }
}
</script>
